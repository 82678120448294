import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'


Vue.prototype.request = axios

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '':'/api';//后端开发环境地址
// axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';//配置请求头信息。

axios.interceptors.response.use(response=>{
    // console.log(response,'====这是返回的结果===')
    if (200==response.status){
       return response.data
    }
},err=>{
  console.log(err,'====请求出错===')
})

Vue.config.productionTip = false

Vue.use(ViewUI)
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
