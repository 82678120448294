<template>
  <div id="app">
	  <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>
<script>
export  default {
	mounted() {
	
	},
	provide(){
		return {
			reload: this.reload
		}
	},
	data(){
		return {
			isRouterAlive: true
		}
	},
	methods: {
		reload(){
			this.isRouterAlive = false
			this.$nextTick(()=>{
				this.isRouterAlive = true
			})
		}
	}
}
</script>
<style lang="scss">
.color-f{
	color: #ffffff!important;
}
.my-flex{
	display: flex;
	flex-direction: row;
	align-items: center;
}

.my-flex-spa-be{
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.my-flex-spa-ar{
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.my-flex-col{
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
