<template>
	<div class="home">
		<div :class="['my-flex-spa-ar home-nav-wrap', {'mobile-nav-height':mobileNavHeight}]">
			<div class="logo-wrap">
				<img class="logo-img" :src="logo" alt="">
			</div>
			<div class="my-flex">
				<div>
				
				</div>
				<Menu @on-select="onRouterChange" v-if=" menusVisible " mode="horizontal" :theme="theme1"
				      active-name="1">
					<MenuItem name="1">
						<Icon type="md-filing" />
						首页
					</MenuItem>
					<MenuItem name="2">
						<Icon custom="iconfont icon-mars_"/>
						未来火星计划
					</MenuItem>
                    <MenuItem name="4">
                        <Icon custom="iconfont icon-mars_"/>
                        定制星空图
                    </MenuItem>
					<MenuItem name="3">
						<Icon custom="iconfont icon-question"/>
						F&Q
					</MenuItem>
				</Menu>
				<div v-if=" !menusVisible "  class="my-flex" style="justify-content: flex-end">
<!--					<Dropdown @on-click="onSelectMenus" style="margin-right: 10px">-->
					<Dropdown @on-click="onSelectMobileMenus">
						<Icon size="40" color="#fff" type="md-menu"></Icon>
						<DropdownMenu slot="list">
							<DropdownItem name="1">
								<Icon type="md-filing" />
								首页</DropdownItem>
							<DropdownItem name="2">
								<Icon custom="iconfont icon-mars_"/>未来火星计划</DropdownItem>
							<DropdownItem name="3">
								<Icon custom="iconfont icon-question"/>F&Q</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</div>
		<div style="height: 60px;width: 100%"></div>
		<!--轮播图-->
		<div class="swiper-wrap">
			<Carousel @on-click="click_carouse"
			          autoplay v-model="value2" loop>
				<CarouselItem v-for=" (item,index) in banner_list " :key="index">
					<div v-if="item.url!==''" class="demo-carousel" :style="{'height': carouseItemMobileHeight}">
						<img style="width: 100%;height: 100%" class="carouse-img" :src=" item.url ">
<!--						<img style="width: 100%;height: 100%" class="carouse-img" src=" https://z3.ax1x.com/2021/03/31/cFHDfO.jpg ">-->
<!--							<el-image-->
<!--								:src="item.url"-->
<!--								fit="fit"></el-image>-->
					</div>
				</CarouselItem>
			</Carousel>
		</div>
		<!--		产品特点介绍-->
		<div id="section-menus" :class=" section1Class ">
			<div :style="{'width': section1_one,'height': '100%', 'margin-left':  margin_left}">
				<img class="section-img" :src=" section1Data.picture " alt="">
			</div>
			<div class="banner-1-menus" :style="{width: section1_one,'padding': '15px'}">
				<div style="text-align: center">{{ section1Data.title }}</div>
				<div class="rich-text" v-html=" section1Data.introduce || '' "></div>
<!--				按钮(电脑)-->
				<div class="btn-bu " v-if="menusVisible">
					<el-button @click="_goTaobao">立即购买</el-button>
				</div>
				<div class="btn-mobile-buy" v-else>
					<el-button @click="_goTaobao">立即购买</el-button>
				</div>
<!--				<div id="menus-position-wrap">-->
				<div :id=" idMobileJump ">
<!--					<Menu  @on-select="onSelectMenus" v-if=" menusVisible " mode="horizontal"-->
					<Menu v-if="!menusVisible"  @on-select="onSelectMenus" mode="horizontal"
					      active-name="1">
<!--						<MenuItem name="1">-->
<!--							<Icon type="ios-paper"/>-->
<!--							产品特点介绍-->
<!--						</MenuItem>-->
						<MenuItem name="2">
							<Icon type="md-filing" />
							产品展示
						</MenuItem>
<!--						<MenuItem name="3">-->
<!--							<Icon type="ios-construct"/>-->
<!--							定制流程-->
<!--						</MenuItem>-->
<!--						<MenuItem name="4">-->
<!--							<Icon type="ios-construct"/>-->
<!--							未来火星计划-->
<!--						</MenuItem>-->
						<MenuItem name="4">
							<Icon type="ios-book" />
							登记册
						</MenuItem>
					</Menu>
					<Menu v-else  @on-select="onSelectMenus" mode="horizontal"
					      active-name="1">
<!--						<MenuItem name="1">-->
<!--							<Icon type="ios-paper"/>-->
<!--							产品特点介绍-->
<!--						</MenuItem>-->
						<MenuItem name="2">
							<Icon type="md-filing" />
							产品展示
						</MenuItem>
<!--						<MenuItem name="3">-->
<!--							<Icon type="ios-construct"/>-->
<!--							定制流程-->
<!--						</MenuItem>-->
						<MenuItem name="4">
							<Icon custom="iconfont icon-mars_"/>
							未来火星计划
						</MenuItem>
<!--						<MenuItem name="5">-->
<!--							<Icon type="ios-book" />-->
<!--							登记册-->
<!--						</MenuItem>-->
					</Menu>
				</div>
			</div>
		</div>
		<!--		产品介绍-->
		<!--		<div class="my-flex-spa-be section-1">-->
		<div :class=" section1Class ">
			<div :style="{'width': section1_one,'margin-left':  margin_left}">
				<!--				循环按钮-->
				<div class="my-flex btn-wrap">
<!--					 style="width: 46%;justify-content: center"-->
					<div @mouseover=" activeCarousel(index) "
					     @mouseleave=" section2DataIndex=-1 "
					     v-for="(item, index) in section2Data"
					     :key="index"
					     :style="{'width': widthBtnItem, 'justify-content': 'center'}"
					     :class="['btn-item my-flex', {'clear-flex':!menusVisible}, {'active-btn': index==section2DataIndex}]">
						<Icon size="30" type="md-star"/>
						<div>
							{{ item.title }}
						</div>
					</div>
				</div>
			</div>
			<div :style="{'width': section1_one,'padding': '15px'}">
				<Carousel @on-click="click_carouse"
				          autoplay
				          v-model="productSwiperIndex"
				          loop>
					<CarouselItem v-for=" (item,index) in section2Data " :key="index">
<!--						<div style="width: 100%;height: 400px" >-->
						<div style="max-width: 100%;height: auto" >
							<img style="width: 100%;height: 100%" :src=" item.picture ">
						</div>
					</CarouselItem>
				</Carousel>
			</div>
		</div>
		<!--		定制流程-->
		<div :class=" section1Class ">
			<div :style="{'width': section1_one,'margin-right': margin_left,'height': '100%'}">
				<img class="section-img" :src=" section3Data.picture " alt="">
			</div>
			<div :style="{'width': section1_one,'padding': '15px'}">
				<div style="text-align: center">{{ section3Data.title }}</div>
				<div class="rich-text" v-html=" section3Data.introduce || ''"></div>
			</div>
		</div>
		<!-- 查询板块 -->
		<div :class=" section1Class ">
			<div :style="{'width': section1_one,'padding': '15px','height': '100%'}">
				<div style="height: 85%">
					<div style="text-align: center">{{ section4Data.title }}</div>
					<div class="rich-text" v-html=" section4Data.introduce || ''"></div>
				</div>
				<div v-if=" menusVisible " class="my-flex-spa-be" style="height: 16%;border-top: 1px solid #e8e8e8;padding-top: 10px;text-align: right">
					<Input v-model="keyword"
					       @on-enter="searchNow"
					       :clearable="true"
					       prefix="md-search"
					       :style=" widthMobile "
					       placeholder="输入证书编号" />
					<Button @click.stop="searchNow" type="primary">查询</Button>
				</div>
<!--				手机端-->
				<div class="my-flex-spa-be" style="height: 16%;border-top: 1px solid #e8e8e8;padding-top: 10px;text-align: right" v-else>
					<Input v-model="keyword"
					       @on-enter="searchNow"
					       :clearable="true"
					       prefix="md-search"
					       placeholder="输入证书编号" />
					<Button @click.stop="searchNow" type="primary">查询</Button>
				</div>
<!--				表格显示-->
				<div class="notice-list-wrap" >
					<div v-if="!menusVisible" style="overflow-x: auto">
						<Table width="375" size="large" border :columns="columns1" :data="table_list">
						</Table>
					</div>
					<Table size="large" v-else border :columns="columns1" :data="table_list">
					</Table>
					<el-table v-if="false" ref="tableRender" :border="true" :data="table_list">

						<el-table-column
							prop="title"
						>
							<template slot="header" slot-scope="scope">
								<div style="color: #ffffff">标记点</div>
								<div style="color: #ffffff">M.loc</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="name"
						>
							<template slot="header" slot-scope="scope">
								<div style="color: #ffffff">所有人</div>
								<div style="color: #ffffff">OWNER</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="location"
						>
							<template slot="header" slot-scope="scope">
								<div style="color: #ffffff">坐标</div>
								<div style="color: #ffffff">Lng&Lat</div>
							</template>
						</el-table-column>
						<el-table-column
							prop="created_at"
						>
							<template slot="header" slot-scope="scope">
								<div style="color: #ffffff">纪念日</div>
								<div style="color: #ffffff">DATE</div>
							</template>
						</el-table-column>
					</el-table>
					<el-button @click.stop="seeMoreBuyerList" type="primary">查看更多</el-button>
				</div>
			</div>
			<div :style="{'width': section1_one,'margin-right': '12px','height': '100%'}">
				<img class="section-img" :src=" section4Data.picture " alt="">
			</div>
		</div>
		<!--		详情页面显示-电脑-->
		<div v-if="!isMobile" class="my-flex-spa-be section-info" style="padding: 0">
			<nav class="nav-wrap" style="width: 30%;height: 100%;">
				<div @mouseover=" section5DataIndex = index  "
				     :class="['my-flex info-item',{'active-data5': index==section5DataIndex}]"
				     v-for=" (item, index) in section5Data " :key="index">
					<Icon size="30" type="md-star"/>
					<span style="font-size: 17px;margin-left: 10px">{{ item.title }}</span>
				</div>
			</nav>
			<div v-if="section5Data[section5DataIndex].introduce || ''" class="data-5-content"
			     style="width: 70%;padding: 15px;height: 100%">
				<div v-html=" section5Data[section5DataIndex].introduce || ''"></div>
			</div>
		</div>
		<div class="mobile-nav-wrap" v-else>
			<el-collapse accordion>
				<el-collapse-item v-for=" (item, index) in section5Data " :key="index">
					<template slot="title">
<!--						<div class="my-flex" style="padding-left: 130px;">-->
						<div class="my-flex" style="">
							<Icon size="30" type="md-star"/>
							<span>{{item.title}}</span>
						</div>
					</template>
					<p v-html="item.introduce || ''"></p>
				</el-collapse-item>
			</el-collapse>
		</div>
		<!--	多出内容显示-->
		<div v-if="more_list.length>0" class="more-list-wrap">
			<div v-for="(item, index) in more_list" :key="index" :class=" section1Class ">
				<div :style="{'width': section1_one,'height': '100%', 'margin-left':  margin_left}">
					<img class="section-img" :src=" item.picture " alt="">
				</div>
				<div :style="{width: section1_one,'padding': '15px'}">
					<div style="text-align: center">{{ item.title }}</div>
					<div class="rich-text" v-html=" item.introduce || ''"></div>
				</div>
			</div>
		</div>
		<!--		文章列表数据-->
		<div v-if="text_list.length>0" :class=" text_list_class ">
			<div @click="_goTextInfo(index)" :key="index" v-for="(item, index) in text_list"
			     class="my-flex-col text-item">
				<Icon size="30" type="md-star"/>
				<span>{{ item.title }}</span>
			</div>
		</div>
		<!--	底部内容显示-->
		<div style="width: 88%" class="my-flex-spa-ar bottom-copyright-wrap">
			<div class="">
				<div>{{ copy_right }}</div>
				<div>公司地址: {{address}}</div>
				<div>{{moreInfo}} <a style="color: #999999" target="_blank" href="https://beian.miit.gov.cn">{{hrefBottomText}}</a> </div>
			</div>
			<span><img :src="qrcode" class="qrcode-wrap-img" alt=""></span>
		</div>
<!--		底部大图片-->
		<div style="max-width: 100%;height: auto">
			<img style="width: 100%;height: 100%" :src="thumb" alt="">
<!--			<img style="width: 100%;height: 100%" src="https://z3.ax1x.com/2021/03/31/cFHDfO.jpg" alt="">-->
		</div>
	</div>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			isMobile: !1, // 是否为手机或者电脑
			keyword: '',
			logo: '',
			theme1: 'dark',
			value2: 0,
			productSwiperIndex: 0,
			banner_list: [],
			section1Data: {
				introduce: ''
			},
			active_menus_color: '#151737',
			section2Data: {}, // 产品介绍
			section3Data: {
				introduce: ''
			},
			section4Data: {},
			section5Data: [],
			section2DataIndex: -1,
			section5DataIndex: 0,
			widthMobile: {
				width: '300px'
			},
			menusVisible: !0,
			mobileNavHeight: !1,
			sectionMobileFlag: !1,
			section1Class: ['section-1', 'my-flex-spa-be', 'query-div'],
			section1_one: '50%',
			margin_left: '12px',
			
			more_list: [],
			text_list: [],
			text_list_class: ['my-flex-spa-be', 'text-list-wrap'],
			qrcode: '',
			copy_right: '',
			widthBtnItem: '46%',
			moreInfo: '',
			address: '',
			thumb: '',
			carouseItemMobileHeight: '600px',
			idMobileJump: 'menus-position-wrap',
			table_list: [],
			
			columns1: [
				{
					// title: 'name',
					key: "title",
					// width: 300,
					renderHeader: (h, params) => {
						return h('div', [
							h('div',{
								style: {
									color:'#ffffff'
								},
							}, '标记点'),
							h('div', {
								style: {
									color:'#ffffff'
								},
							},'M.loc')
						]);
					}
				},
				{
					key: "name",
					renderHeader: (h, params) => {
						return h('div', [
							h('div',{
								style: {
									color:'#ffffff'
								},
							}, '所有人'),
							h('div', {
								style: {
									color:'#ffffff'
								},
							},'OWNER')
						]);
					}
				},
				{
					key: "location",
					renderHeader: (h, params) => {
						return h('div', [
							h('div',{
								style: {
									color:'#ffffff'
								},
							}, '坐标'),
							h('div', {
								style: {
									color:'#ffffff'
								},
							},'Lng&Lat')
						]);
					}
				},
				{
					key: "created_at",
					renderHeader: (h, params) => {
						return h('div', [
							h('div',{
								style: {
									color:'#ffffff'
								},
							}, '纪念日'),
							h('div', {
								style: {
									color:'#ffffff'
								},
							},'DATE')
						]);
					}
				}
			],
            hrefBottomText: '',// 底部超链接文字
		}
	},
	components: {},
	inject: ['reload'],
	mounted() {
		document.body.style.backgroundColor = '#151638!important'
		this.loadList()
		this.getScreen()
		this.loadData()
		this._loadMore()
		this._loadTextList()
		this.loadList()
		this._loadCopyRight()
	},
	beforeCreate() {
		localStorage.removeItem('_data')
	},
	methods: {
		_goTaobao(){
			window.open( "https://item.taobao.com/item.htm?spm=a230r.1.14.1.283b28d7wLdG2j&id=641768935889&ns=1&abbucket=15#detail", "_blank");
		},
		renderHeader(h, {column}){
			return h(
				'div',
				[
					h('span', column.label),
					h('i', {
						class:'el-icon-location',
						style:'color:#409eff;margin-left:5px;'
					})
				],
			)
		},
		/**
		 * 加载花名册
		 */
		loadList(){
			this.request({
				url: '/api/getCoordinate_hmc',
				params: {
					page: 1
				}
			}).then(res=>{
				this.loading = !1
				if (res.data.data.length>0){
					const _checkStr = (arg1, arg2)=>{
						let temp1 = ''
						let temp2 = ''
						// arg1: 纬度
						// arg2: 经度
						// console.log(arg1, arg2);
						if (arg2<0){
							temp1 = `${Math.abs(arg2)}E`
						}else{
							temp1 = `${arg2}W`
						}
						if (arg1<0){
							temp2 = `${Math.abs(arg1)}S`
						}else{
							temp2 = `${arg1}N`
						}
						// console.log(temp2, temp1,'====faskldjfklasjdlfk====');
						return `${temp2}, ${temp1}`
					}
					let tempList = res.data.data.map(item=>{
						return {
							title: item.title,
							name: item.name,
							created_at: item.mark,
							location: _checkStr(Number(item.latitude), Number(item.longitude))
						}
					})
					if (tempList.length>5){
						tempList.length = 5
					}
					this.table_list = tempList
					this.$refs.tableRender.doLayout()
					localStorage.setItem('_table', JSON.stringify(tempList))
					// this.$forceUpdate()
					// this.reload()
				}
				console.log(res,'======花名册数据=====');
			})
		},
		/**
		 * 路由切换
		 */
		onRouterChange(e){
			console.log(e);
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}else if (4==e){ // 定制星图
			    window.open('https://xt.starjourneys.net/h/#/')
            }
		},
		/**
		 * 查看更多购买者
		 */
		seeMoreBuyerList(){
			console.log('okk');
			this.$router.push({
				path: '/list'
			})
		},
		onSelectMobileMenus(e){
			console.log(e,'====我是触发===');
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}
		},
		getScreen() {
			let w = document.documentElement.offsetWidth || document.body.offsetWidth;
			if (w < 1000) { // 手机
				this.widthMobile.width = '100px'
				this.menusVisible = !1
				this.mobileNavHeight = !0
				let secion1Class = ['section-1', 'my-flex-col', 'query-div']
				this.section1Class = secion1Class
				this.section1_one = '100%'
				this.margin_left = '0'
				this.text_list_class = ['my-flex-spa-be', 'mobile-text-list-wrap']
				this.widthBtnItem = '41%'
				this.isMobile = !0
				this.carouseItemMobileHeight= '250px'
				this.idMobileJump = ''
			}
			console.log(w, '====屏幕宽度====')
		},
		_loadMore() {
			this.request({
				url: '/api/getIntroduce_all'
			}).then(res => {
				console.log(res, '=====更多的内容====');
				if (0 == res.code) {
					if (res.data.length > 0) {
						this.more_list = res.data
					}
				}
			})
		},
		_goTextInfo(index) {
			this.$router.push({
				// path: `/info/${this.text_list[index].id}`,
				path: `/question`,
			})
		},
		_loadCopyRight() {
			this.request({
				url: '/api/getSetting'
			}).then(res => {
				console.log(res, '=====getSetting====');
				if (0 == res.code) {
					this.logo = res.data.logo
					this.qrcode = res.data.qrcode
					this.copy_right = res.data.copy_right
					this.moreInfo = res.data.more.substring(0, res.data.more.indexOf('备案号'))
                    if (res.data.more.indexOf('备案号')!=-1){
                        let startIndex = res.data.more.indexOf('备案号')
                        let endIndex = res.data.more.length
                        this.hrefBottomText = res.data.more.substring(startIndex, endIndex)
                    }
					this.address = res.data.address
					this.thumb = res.data.thumb
					localStorage.setItem('logo', res.data.logo)
					localStorage.setItem('thumb', res.data.thumb)
				}
			})
		},
		/**
		 * 获取文章列表数据
		 * @private
		 */
		_loadTextList() {
			this.request({
				url: '/api/getArticles'
			}).then(res => {
				console.log(res, '=====getArticles====');
				if (0 == res.code) {
					if (res.data.length > 0) {
						this.text_list = res.data
					}
				}
			})
		},
		searchNow() {
			console.log('okk')
			if ('' == this.keyword) {
				this.$Message.warning('请输入编号');
				return
			}
			this.request({
				url: `/api/getCoordinateDetail/${this.keyword}`
			}).then(res => {
				console.log(res, '=====getCoordinateDetail====');
				let data = JSON.stringify(res.data)
				if (0 == res.code) {
					if (Array.isArray(res.data) && 0 == res.data.length) {
						this.$Message.warning('未查询到数据');
					} else {
						localStorage.setItem('_data', data)
						this.$router.push({
							path: `/about`,
						})
					}
				}
			})
		},
		//section2DataIndex=index
		activeCarousel(index) {
			this.section2DataIndex = index
			this.productSwiperIndex = index
		},
		loadData() {
			//加载banner
			const loadBanner = this.request({url: '/api/getBanner',})
			// 加载产品特点
			const data1 = this.request({url: '/api/getIntroduce_1'})
			// 加载产品介绍
			const data2 = this.request({url: '/api/getProduct'})
			// 定制流程
			const data3 = this.request({url: '/api/getIntroduce_2'})
			// 查询
			const data4 = this.request({url: '/api/getIntroduce_3'})
			// 详情介绍
			const data5 = this.request({url: '/api/getIntroduce2'})
			
			Promise.all([loadBanner, data1, data2, data3, data4, data5]).then(res => {
				console.log(res, '====返回结果====')
				this.banner_list = res[0].data
				this.section1Data = res[1].data
				this.section2Data = res[2].data
				this.section3Data = res[3].data
				this.section4Data = res[4].data
				if (res[5].data.length > 4) {
					res[5].data.length = 4
				}
				this.section5Data = res[5].data
			})
			
			// .then(res=>{
			// 	console.log(res,'====轮播图====')
			// 	this.banner_list = res.data
			// })
		},
		onSelectMenus(e) {
			console.log(e)
			if ('1' == e) {
				this._jump(0)
			} else if ('2' == e) {
				this._jump(1)
			} else if ('3' == e) {
				this._jump(2)
			} else if ('4' == e) {
				this._jump(3)
			} else if ('5' == e) {
				this._jump(3)
			}
		},
		_jump(index) {
			let jump = document.querySelectorAll('.query-div')
			// 获取需要滚动的距离
			let total = jump[index].offsetTop - 70
			// Chrome
			document.body.scrollTop = total
			// Firefox
			document.documentElement.scrollTop = total
			// Safari
			window.pageYOffset = total
		},
		click_carouse(e) {
			console.log(e, '312321')
			console.log(this.banner_list[e]);
			if (this.banner_list[e].src && this.banner_list[e].src!==''){
				let loca = window.open("_blank")
				loca.location = this.banner_list[e].src
			}
		}
	}
}
</script>

<style>
	body {
		background-color: #000000 !important;
	}
	/*#menus-position-wrap .ivu-menu-item-active{*/
	/*	color: #000000;*/
	/*	border-color: #000000!important;*/
	/*}*/
	.notice-list-wrap .el-table th{
		background-color: #348FED !important;
		color: #ffffff!important;
	}
	.notice-list-wrap .el-table tr{
		font-size: 12px!important;
	}
	
	.notice-list-wrap .ivu-table th{
		background-color: #348FED !important;
		color: #ffffff!important;
	}
	.notice-list-wrap .ivu-table tr{
		font-size: 12px!important;
		padding: 15px 0!important;
	}
	.notice-list-wrap .ivu-table .ivu-table-tbody td{
		padding: 15px 0!important;
	}
</style>

<style lang="scss" scoped>
@import "../style/home";
</style>
