import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/map',
        name: 'Map',
        component: () => import('@/components/Map.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/About.vue')
    },
    {
        path: '/info/:id',
        name: 'Info.vue',
        component: () => import('@/views/Info.vue')
    },
    {
        path: '/question',
        name: 'question',
        component: () => import('@/views/Question.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('@/views/List.vue')
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
